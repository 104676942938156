<template>
  <v-col class="text-left" cols="12">
    <div class="scroll-area-md">
      <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">

        <div v-if="this.workshift_started > 0" class="vertical-timeline-element">
          <div><span class="vertical-timeline-element-icon bounce-in"><i
              class="badge badge-dot badge-dot-xl badge-primary"> </i></span>
            <div class="vertical-timeline-element-content bounce-in"><h4
                class="timeline-title">{{ $t('generic.lang_ShiftStarted1') }}
              {{
                this.dateFormater(new
                Date(this.workshift_started))
              }} {{ $t('generic.lang_ShiftStarted2') }}</h4>
              <p><strong class="success--text">{{ user.fullname }}</strong>{{ $t('generic.lang_ShiftStarted3') }}<a
                  href="javascript:void(0);">{{ this.started }} </a></p><span
                  class="vertical-timeline-element-date">{{ this.timeFormater(new Date(this.workshift_started)) }}</span>
            </div>
          </div>
        </div>


        <div v-for="(item , key) in this.pauseTimeJSON"
             :key="key">

          <!--started time -->
          <div v-if="item.start>0" class="vertical-timeline-element">
            <div><span class="vertical-timeline-element-icon bounce-in"><i
                :class="(item.end > 0)? 'badge badge-dot badge-dot-xl badge-warning' : 'badge badge-dot badge-dot-xl badge-danger'"> </i></span>
              <div class="vertical-timeline-element-content bounce-in"><h4
                  class="timeline-title">{{ $t('generic.lang_your') }} {{ ordinalNumbers(key) }}
                {{ $t('generic.lang_pauseStarted') }}<span
                    v-if="(item.end > 0)">({{ calcWorkTime(item.start * 1000, item.end * 1000) }})</span>
              </h4>
                <p v-if="(item.end > 0)">{{ $t('generic.lang_pauseFinishedAt') }}: <span
                    class="warning--text">{{ dateTimerFormater(new Date(item.end * 1000)) }}</span>
                </p>
                <p v-else class="error--text">{{ $t('generic.lang_breakOpen') }}</p>
                <span class="vertical-timeline-element-date">{{ timeFormater(new Date(item.start * 1000)) }}</span>
              </div>
            </div>
          </div>
          <!--ended time -->
          <div v-if="item.end>0" class="vertical-timeline-element">
            <div><span class="vertical-timeline-element-icon bounce-in"><i
                class="badge badge-dot badge-dot-xl badge-success"> </i></span>
              <div class="vertical-timeline-element-content bounce-in"><h4
                  class="timeline-title">{{ $t('generic.lang_your') }} {{ ordinalNumbers(key) }}
                {{ $t('generic.lang_pauseFinishedAt') }}: <span
                    class="success--text">{{ dateTimerFormater(new Date(item.end * 1000)) }}</span>
              </h4>
                <p>{{ $t('generic.lang_greateWorkDay') }} <span
                    class="success--text">{{ user.fullname }}</span>
                </p>
                <span class="vertical-timeline-element-date">{{ timeFormater(new Date(item.start * 1000)) }}</span>
              </div>
            </div>
          </div>

        </div>


        <div v-if="this.workshift_ended > 0" class="vertical-timeline-element">
          <div><span class="vertical-timeline-element-icon bounce-in"><i
              class="badge badge-dot badge-dot-xl badge-danger"> </i></span>
            <div class="vertical-timeline-element-content bounce-in"><h4
                class="timeline-title">{{ $t('generic.lang_ShiftStarted1') }} {{
                this.dateFormater(new
                Date(this.workshift_ended))
              }} {{ $t('generic.lang_shiftClosed2') }}</h4>
              <p><strong>{{ this.user.fullname }}</strong>{{ $t('generic.lang_shiftClosed3') }} <a
                  href="javascript:void(0);">{{ this.ended }} </a></p><span
                  class="vertical-timeline-element-date">{{ this.timeFormater(new Date(this.workshift_ended)) }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </v-col>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

let u = createNamespacedHelpers('user');

export default {
  name: "TimeLines",
  components: {
    VuePerfectScrollbar
  },
  computed: {
    ...u.mapGetters(['user']),
  },
  props: {
    workshift_ended: {
      type: Number,
      default: 0
    },
    workshift_started: {
      type: Number,
      default: 0
    },
    pauseTimeJSON: {
      type: Array,
      default: null
    }
  },
  watch: {
    workshift_ended(val) {
      this.end = val > 0;
    }
  },
  data: () => {
    return {
      started: "",
      ended: "",
      timers: []
    }
  },
  beforeDestroy() {
    this.timers.forEach((item) => {
      clearTimeout(item)
    })
  },
  mounted() {
    this.setStarted();
    this.setEnded();
  },
  methods: {
    setStarted() {
      const self = this;
      this.timers.push(
          setInterval(function () {
            if (self.workshift_started > 0) {
              self.started = self.$moment(new Date(self.workshift_started)).from(new Date());
            }
          }, 1))
    },
    setEnded() {
      const self = this;
      this.timers.push(
          setInterval(function () {
            if (self.workshift_ended > 0) {
              self.ended = self.$moment(new Date(self.workshift_ended)).from(new Date());
            }
          }, 1))
    },
    calcWorkTime(start, end) {
      const deff = (end - start);
      let s = deff / 1000;
      let hours = Math.floor(s / 3600);
      const minutes = Math.floor((s - (hours * 3600)) / 60);

      let str = (hours < 10 ? "0" + hours : hours) + "H:" + (minutes < 10 ? "0" + minutes : minutes) + "M";
      return str;
    }
    ,
    ordinalNumbers(number) {
      const numbers = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", "16th", "17th",
        "18th", "19th", "20th", "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th", "31th"];
      return numbers[number];
    }
    ,
    dateTimerFormater(date) {
      let str = date.toLocaleDateString() + " " + date.toLocaleTimeString();
      return str
    }
    ,
    dateFormater(date) {
      return date.toLocaleDateString();
    }
    ,
    timeFormater(date) {
      let str = date.toLocaleTimeString();
      return date.getHours() + ":" + date.getMinutes() /*+ " " + str.split(' ')[1]*/;
    }
    ,
  }
}
</script>

<style scoped>

</style>