<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('generic.lang_personalTimeTrack') "
                :icon="icon"
                :subheading="$t('generic.lang_personalTimeTrack')"
        ></page-title>
        <div class="app-main__inner">
            <time-track-component />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import TimeTrackComponent from "../../../components/timesheet/timetrack/TimeTrackComponent";

    export default {
        components: {
            TimeTrackComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-stopwatch icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>