<template>

  <div class="mx-auto text-center" id="countDownContainer">

    <!-- hours -->
    <ul class="flip mx-auto hoursPlay-left">
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">0</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">0</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">1</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">1</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">2</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">2</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">3</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">3</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">4</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">4</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">5</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">5</div>
          </div>
        </a>
      </li>

      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">6</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">6</div>
          </div>
        </a>
      </li>

      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">7</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">7</div>
          </div>
        </a>
      </li>

      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">8</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">8</div>
          </div>
        </a>
      </li>

      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">9</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">9</div>
          </div>
        </a>
      </li>
    </ul>
    <ul class="flip mx-auto hoursPlay-right">
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">0</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">0</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">1</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">1</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">2</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">2</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">3</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">3</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">4</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">4</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">5</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">5</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">6</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">6</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">7</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">7</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">8</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">8</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">9</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">9</div>
          </div>
        </a>
      </li>
    </ul>
    <span class="flip-clock-divider seconds mx-auto"><span
        class="flip-clock-dot top"></span><span class="flip-clock-dot bottom"></span></span>
    <!--minutes -->
    <ul class="flip mx-auto minutesPlay-left">
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">0</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">0</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">1</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">1</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">2</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">2</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">3</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">3</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">4</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">4</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">5</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">5</div>
          </div>
        </a>
      </li>
    </ul>
    <ul class="flip mx-auto minutesPlay-right">
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">0</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">0</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">1</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">1</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">2</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">2</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">3</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">3</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">4</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">4</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">5</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">5</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">6</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">6</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">7</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">7</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">8</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">8</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">9</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">9</div>
          </div>
        </a>
      </li>
    </ul>
    <span class="flip-clock-divider seconds mx-auto"><span
        class="flip-clock-dot top"></span><span class="flip-clock-dot bottom"></span></span>

    <!-- seconds -->
    <ul class="flip mx-auto secondPlay-left">
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">0</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">0</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">1</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">1</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">2</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">2</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">3</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">3</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">4</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">4</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">5</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">5</div>
          </div>
        </a>
      </li>
    </ul>
    <ul class="flip mx-auto secondPlay-right">
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">0</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">0</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">1</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">1</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">2</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">2</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">3</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">3</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">4</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">4</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">5</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">5</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">6</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">6</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">7</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">7</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">8</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">8</div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="up">
            <div class="shadow"></div>
            <div class="inn">9</div>
          </div>
          <div class="down">
            <div class="shadow"></div>
            <div class="inn">9</div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "StopWatch",
  props: {
    timestamp: {
      type: Number,
      default: 0
    },
    currentTime: {
      type: Number,
      default: 0
    }
  },
  data: () => {
    return {
      timers: [],
      globalDeff: 0,
    }
  },
  watch: {
    timestamp(val) {
      if (this.timestamp !== 0 && this.timestamp !== undefined && this.timestamp !== null) {
        this.clear();
        this.stop();
        val & this.Init();
      } else {
        this.stop();
      }

    }
  },
  created() {
    let serverDate = (this.currentTime === 0 || this.currentTime === undefined) ? "" : this.currentTime,
        userDate = new Date();

    if ((serverDate - userDate) > 0) {
      //   //console.log()
    }

    if (this.timestamp !== 0 && this.timestamp !== undefined && this.timestamp !== null) {
      this.Init();
    }
  },
  beforeDestroy() {
    this.stop();
  },
  methods: {

    Init() {

      this.resetElement("secondPlay-right");
      let current = new
          Date((this.currentTime === 0 || this.currentTime === undefined) ? "" : this.currentTime),
          startDate = new Date(this.timestamp);
      const deff = (current - startDate);

      if (deff <= 0)
        this.start();
      else {
        let s = deff / 1000;
        let hours = Math.floor(s / 3600);
        const minutes = Math.floor((s - (hours * 3600)) / 60);
        const seconds = s - (hours * 3600) - (minutes * 60);
        // --hours;
        if (hours > 0) {
          if (hours.toString().length === 1) {
            this.initElement("hoursPlay-right", hours);
            this.initElement("hoursPlay-left", 0);
          } else {
            this.initElement("hoursPlay-right", parseInt(hours.toString()[1]));
            this.initElement("hoursPlay-left", parseInt(hours.toString()[0]));
          }
        }

        if (minutes > 0) {
          if (minutes.toString().length === 1) {
            this.initElement("minutesPlay-right", minutes);
            this.initElement("minutesPlay-left", 0);
          } else {
            this.initElement("minutesPlay-right", parseInt(minutes.toString()[1]));
            this.initElement("minutesPlay-left", parseInt(minutes.toString()[0]));
          }
        }

        if (seconds > 0) {
          if (seconds.toString().length === 1) {
            this.initElement("secondPlay-right", seconds);
            this.initElement("secondPlay-left", 0);
          } else {
            this.initElement("secondPlay-right", parseInt(seconds.toString()[1]));
            this.initElement("secondPlay-left", parseInt(seconds.toString()[0]));
          }
        }


        this.start();
      }
    },
    initElement(elementClass, number) {

      let t = setInterval(() => {

        if (document.getElementById('countDownContainer'))
          document.getElementById('countDownContainer').classList.remove("play")

        let aa = document.querySelector("ul." + elementClass + " li.active");
        if (!aa) {

          if (document.querySelectorAll("ul." + elementClass + " li")[0]) {
            aa = document.querySelectorAll("ul." + elementClass + " li")[0];

            aa.classList.add("before");
            aa.classList.remove("active");


            let nextLi = aa.nextSibling;
            //search for the nex li
            while (nextLi) {
              if (nextLi.matches('li')) {
                nextLi.classList.add("active");
                if (nextLi.closest("#countDownContainer")) nextLi.closest("#countDownContainer").classList.add("play");
                nextLi.querySelector('.up>.inn').innerText = number;
                nextLi.querySelector('.down>.inn').innerText = number;
                // if (parseInt(nextLi.querySelector('.inn').innerText) === number) {
                clearInterval(t);
                return;
//                }
              }

              nextLi = nextLi.nextSibling;
            }

          }
        } else if (aa && aa.parentNode.lastChild?.classList.contains("active")) {
          if (document.querySelector("ul." + elementClass + " li"))
            document.querySelector("ul." + elementClass + " li").classList.remove("before");

          aa.classList.add("before");
          aa.classList.remove("active");


          aa = document.querySelector("ul." + elementClass + " li")
          if (aa && Array.isArray(aa) && aa.length > 0) aa = aa[0];

          aa.classList.add("active");
          if (aa.closest("#countDownContainer")) aa.closest("#countDownContainer").classList.add("play")
          aa.querySelector('.up>.inn').innerText = number;
          aa.querySelector('.down>.inn').innerText = number;

          //if (parseInt(aa.querySelector('.inn').innerText) === number) {
          clearInterval(t);
          return;
//          }
        } else {
          if (document.querySelector("ul." + elementClass + " li"))
            document.querySelector("ul." + elementClass + " li").classList.remove("before");
          aa.classList.add("before");
          aa.classList.remove("active");

          let nextLi = aa.nextSibling;
          //search for the nex li
          while (nextLi) {
            if (nextLi.matches('li')) {
              nextLi.classList.add("active");
              if (nextLi.closest("#countDownContainer")) nextLi.closest("#countDownContainer").classList.add("play");
              nextLi.querySelector('.up>.inn').innerText = number;
              nextLi.querySelector('.down>.inn').innerText = number;
              //if (parseInt(nextLi.querySelector('.inn').innerText) === number) {
              clearInterval(t);
              return;
              //}
            }

            nextLi = nextLi.nextSibling;
          }
        }

        clearInterval(t);
      }, 1)
    },
    start() {
      const self = this;
      this.timers = [];
      this.timers.push(setInterval(function () {
        self.Play("secondPlay-right")
      }, 1000));
    }
    ,
    stop() {
      this.timers.forEach((elt) => {
        clearInterval(elt);
      });
    },
    resetElement(elementClass) {
      const elems = document.querySelectorAll("ul." + elementClass + " li");

      [].forEach.call(elems, function (el) {
        el.classList.remove("active");
        el.classList.remove("before");
      });
    },
    clear() {

      this.resetElement("hoursPlay-right");
      this.resetElement("hoursPlay-left");

      this.resetElement("minutesPlay-right");
      this.resetElement("minutesPlay-left");

      this.resetElement("secondPlay-right");
      this.resetElement("secondPlay-left");
    },
    checker(elementClass) {
      let aa = document.querySelector("ul." + elementClass + " li.active");
      const self = this;

      if (elementClass === "secondPlay-right") {

        let leftActivated = document.querySelector("ul.secondPlay-left li.active");

        if (aa?.parentNode.lastChild?.classList.contains("active") && leftActivated?.parentNode.lastChild?.classList.contains("active")) {//if last seconds-right && seconds-left
          let minutesRight = document.querySelector("ul.minutesPlay-right li.active")
          if (minutesRight?.parentNode?.lastChild?.classList.contains("active")) { //if last seconds-right && seconds-left && minutes-right

            let minutesleft = document.querySelector("ul.minutesPlay-left li.active")
            if (minutesleft?.parentNode.lastChild?.classList.contains("active")) { //if last seconds-right && seconds-left && minutes-right && minutes-left

              let hoursright = document.querySelector("ul.hoursPlay-right li.active")
              if (hoursright?.parentNode.lastChild?.classList.contains("active")) {//if last seconds-right && seconds-left && minutes-right && minutes-left && hours-right

                let timer1 = setInterval(function () {
                  self.Play("secondPlay-left", timer1)
                }, 1000)

                let timer2 = setInterval(function () {
                  self.Play("minutesPlay-right", timer2)
                }, 1000)

                let timer3 = setInterval(function () {
                  self.Play("minutesPlay-left", timer3)
                }, 1000);

                let timer4 = setInterval(function () {
                  self.Play("hoursPlay-right", timer4)
                }, 1000);

                let timer5 = setInterval(function () {
                  self.Play("hoursPlay-left", timer5)
                }, 1000);
              } else {
                let timer1 = setInterval(function () {
                  self.Play("secondPlay-left", timer1)
                }, 1000)

                let timer2 = setInterval(function () {
                  self.Play("minutesPlay-right", timer2)
                }, 1000)

                let timer3 = setInterval(function () {
                  self.Play("minutesPlay-left", timer3)
                }, 1000);

                let timer4 = setInterval(function () {
                  self.Play("hoursPlay-right", timer4)
                }, 1000);
              }
            } else {
              let timer1 = setInterval(function () {
                self.Play("secondPlay-left", timer1)
              }, 1000)

              let timer2 = setInterval(function () {
                self.Play("minutesPlay-right", timer2)
              }, 1000)

              let timer3 = setInterval(function () {
                self.Play("minutesPlay-left", timer3)
              }, 1000);
            }
          } else {
            let timer1 = setInterval(function () {
              self.Play("secondPlay-left", timer1)
            }, 1000);

            let timer2 = setInterval(function () {
              self.Play("minutesPlay-right", timer2)
            }, 1000);

          }
          return;
        } else if (aa && aa.parentNode.lastChild === aa) {
          let timer = setInterval(function () {
            self.Play("secondPlay-left", timer)
          }, 1000)
        }
        return;
      }

    },
    Play(elementClass, timer = null) {
      if (document.getElementById('countDownContainer')) document.getElementById('countDownContainer').classList.remove('play');

      let ulElement = document.querySelector("ul." + elementClass + " li.active");

      if (!ulElement) {
        ulElement = document.querySelector("ul." + elementClass + " li");

        ulElement.classList.add('before');
        ulElement.classList.remove('active');

        let nextLi = ulElement.nextSibling;
        //search for the nex li
        while (nextLi) {
          if (nextLi.matches('li')) {
            nextLi.classList.add("active");
            if (nextLi.closest("#countDownContainer")) nextLi.closest("#countDownContainer").classList.add("play");
            break;
          }

          nextLi = nextLi.nextSibling;
        }

      } else if (ulElement && ulElement.parentNode.lastElementChild.classList.contains('active')) {

        if (this.isIssetArr(Array.from(document.querySelectorAll("ul." + elementClass + " li")))) [].forEach.call(document.querySelectorAll("ul." + elementClass + " li"), function (li) {
          li.classList.remove('before');
        });

        ulElement.classList.add('before');
        ulElement.classList.remove('active');

        ulElement = document.querySelector("ul." + elementClass + " li");
        if (ulElement) {
          ulElement.classList.add('active');

          if (ulElement.closest("#countDownContainer")) ulElement.closest("#countDownContainer").classList.add('play');
        }

      } else {

        if (this.isIssetArr(Array.from(document.querySelectorAll("ul." + elementClass + " li")))) [].forEach.call(document.querySelectorAll("ul." + elementClass + " li"), function (li) {
          li.classList.remove('before');
        });

        ulElement.classList.add('before');
        ulElement.classList.remove('active');

        let nextLi = ulElement.nextSibling;
        //search for the nex li
        while (nextLi) {
          if (nextLi.matches('li')) {
            nextLi.classList.add("active");
            if (nextLi.closest("#countDownContainer")) nextLi.closest("#countDownContainer").classList.add("play");
            break;
          }

          nextLi = nextLi.nextSibling;
        }
      }

      if (timer !== null) {
        clearInterval(timer)
        clearTimeout(timer);
      }


      this.checker(elementClass);

    }
  }
}
</script>

<style scoped>


a {
  cursor: pointer;
  text-decoration: none;
  color: #ccc;
}

a:hover {
  color: #fff;
}

ul {
  list-style: none
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

#social p {
  margin-bottom: 10px;
}

#social ul,
#social li {
  display: inline-block;
}

/* Skeleton */

ul.flip {
  position: relative;
  display: inline-block;
  margin: 5px !important;
  width: 60px;
  height: 90px;
  font-size: 80px;
  font-weight: bold;
  line-height: 87px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .7);
}

ul.flip li {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

}

ul.flip li:first-child {
  z-index: 2;
}

ul.flip li a {
  display: block;
  height: 100%;
  perspective: 200px;
}

ul.flip li a div {
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
}

ul.flip li a div .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

ul.flip li a div.up {
  transform-origin: 50% 100%;
  top: 0;
}

ul.flip li a div.up:after {
  content: "";
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 3px;
  background-color: rgba(0, 0, 0, .4);
}

ul.flip li a div.down {
  transform-origin: 50% 0%;
  bottom: 0;
}

ul.flip li a div div.inn {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200%;
  color: #ccc;
  text-shadow: 0 1px 2px #000;
  text-align: center;
  background-color: #333;
  border-radius: 6px;
}

ul.flip li a div.up div.inn {
  top: 0;

}

ul.flip li a div.down div.inn {
  bottom: 0;
}

/* PLAY */

#countDownContainer.play ul li.before {
  z-index: 3;
}

#countDownContainer.play ul li.active {
  animation: asd .5s .5s linear both;
  z-index: 2;
}

@keyframes asd {
  0% {
    z-index: 2;
  }
  5% {
    z-index: 4;
  }
  100% {
    z-index: 4;
  }
}

#countDownContainer.play ul li.active .down {
  z-index: 2;
  animation: turn .5s .5s linear both;
}

@keyframes turn {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

#countDownContainer.play ul li.before .up {
  z-index: 2;
  animation: turn2 .5s linear both;
}

@keyframes turn2 {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-90deg);
  }
}

/* SHADOW */

#countDownContainer.play ul li.before .up .shadow {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, .1)), color-stop(100%, rgba(0, 0, 0, 1)));
  background: linear-gradient(to top, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  animation: show .5s linear both;
}

#countDownContainer.play ul li.active .up .shadow {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, .1)), color-stop(100%, rgba(0, 0, 0, 1)));
  background: linear-gradient(to top, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  animation: hide .5s .3s linear both;
}

/*DOWN*/

#countDownContainer.play ul li.before .down .shadow {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, .1)));
  background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  animation: show .5s linear both;
}

#countDownContainer.play ul li.active .down .shadow {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, .1)));
  background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  animation: hide .5s .3s linear both;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.flip-clock-divider {
  display: inline-block;
  position: relative;
  margin: 5px !important;
  width: 20px;
  height: 100px;
  padding: 0px 7px 0 7px;
}

.flip-clock-divider:first-child {
  width: 0;
}

.flip-clock-dot.top {
  top: 30px;
}

.flip-clock-dot.bottom {
  bottom: 23px;
}

.flip-clock-dot {
  display: block;
  background: #323434;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  left: 5px;
}

</style>
