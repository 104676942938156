<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-card class="pa-0 ma-0" elevation="0">
      <div class="pa-2 profile-responsive bg-info">

        <v-row align="center" class="ma-0 pa-0" justify="center">
          <v-col class="ma-0 pa-0 text-left" cols="6">

            <v-avatar class="ma-1" color="white" id="avatar" size="50" tile>
              <img
                  :alt="this.user.fullname"
                  :src="this.user.avatarURI"
              >
            </v-avatar>
            <span class="pl-2 mt-6 white--text">{{ this.user.fullname }}</span>
          </v-col>
          <v-col class="text-right ma-0 pa-0" cols="6">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">

                <button @click="startTracking"
                        class="btn btn-sm pa-0 mr-2 btn-icon-vertical btn-success timerControls"
                        type="button"
                        v-on="on" v-show="start">
                  <font-awesome-icon :icon="['fal' , 'stopwatch']" class="white--text" prefix="fal"
                                     size="2x"/>

                </button>
              </template>
              <span>{{ $t('generic.lang_start') }}</span>
            </v-tooltip>


            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <button :disabled="loadPause" @click="pauseTracking()"
                        class="btn btn-sm pa-0 mr-2 btn-icon-vertical btn-warning timerControls"
                        type="button"
                        v-on="on" v-show="pause">
                  <font-awesome-icon :icon="['fal' , 'pause']" class="white--text" prefix="fal"
                                     size="2x"/>
                  <!--<div class="white--text pt-1">{{$t('generic.lang_breakTime')}}</div>-->
                </button>
              </template>
              <span>{{ this.$t('generic.lang_breakTime') }}</span>
            </v-tooltip>


            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <button :disabled="loadResume" @click="continueTracking()"
                        class="btn btn-sm pa-0 mr-2 btn-icon-vertical btn-success timerControls"
                        type="button"
                        v-on="on" v-show="resume">
                  <font-awesome-icon :icon="['fal' , 'play']" class="white--text" prefix="fal"
                                     size="2x"/>
                  <!--<div class="white--text pt-1">{{$t('generic.lang_timeTrackContinue')}}</div>-->
                </button>
              </template>
              <span>{{ this.$t('generic.lang_timeTrackContinue') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">

                <button :disabled="loadingStop" @click="stopTracking"
                        class="btn btn-sm pa-0 mr-2 btn-icon-vertical btn-danger timerControls"
                        type="button"
                        v-on="on" v-show="stop">
                  <font-awesome-icon :icon="['fal' , 'stop']" class="white--text" prefix="fal"
                                     size="2x"/>
                  <!--<div class="white--text pt-1">Stop</div>-->
                </button>
              </template>
              <span>{{ $t('generic.lang_stop') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </div>


      <v-card-text class="text-center pb-12 pt-1 ma-0">
        <v-row align-content="center" class="pb-6" justify="center">
          <v-col class="pt-2 pb-0 mb-0" cols="12" v-if="this.displayCurrentTime">
            <div class="grey--text font-weight-bolder">
              <span id="hours"></span> :
              <span id="minutes"></span> :
              <span id="seconds"></span>
            </div>
          </v-col>


          <v-col class="text-center" cols="12">
            <stop-watch ref="stopwatch" v-bind:current-time="currentTime" v-bind:timestamp="time"/>
          </v-col>

          <time-lines v-bind:pause-time-j-s-o-n="this.pauseTimeJSON"
                      v-bind:workshift_ended="this.workshift_ended"
                      v-bind:workshift_started="this.workshift_started"
                      v-if="this.workshift_started > 0"/>


        </v-row>
      </v-card-text>


    </v-card>
  </v-container>
</template>

<script>

import {library} from '@fortawesome/fontawesome-svg-core';
import {faPause, faPlay, faStop, faStopwatch} from '@fortawesome/pro-light-svg-icons';
import {ENDPOINTS} from "@/config"
import {Events} from "@/plugins/events"
import {createNamespacedHelpers} from 'vuex'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";


import StopWatch from "./StopWatch";
import TimeLines from "./TimeLines";


library.add(
    faStopwatch, faPause, faStop, faPlay
);

let u = createNamespacedHelpers('user');
export default {
  name: "TimeTrackComponent",
  components: {
    TimeLines,
    StopWatch,
    'font-awesome-icon': FontAwesomeIcon,

  },
  data: () => {
    return {
      ENDPOINTS,
      hours: "00",
      minutes: "00",
      seconds: "00",
      displayCurrentTime: true,
      pauseMode: 0,
      start: true,
      stop: true,
      resume: true,
      pause: true,
      workshift_ended: 0,
      workshift_started: 0,
      currentTime: null,
      loadResume: false,
      loadPause: false,
      loadingStop: false,
      loadingStart: false,
      timeTrackTimer: null,
      stopwatch: null,
      time: null,
      pauseTimeJSON: null
    }
  },
  computed: {
    ...u.mapGetters(['user'])
  },
  mounted() {
    this.loadTrackShiftInfo();
  },
  beforeDestroy() {
    clearInterval(this.timeTrackTimer);
  },
  methods: {
    calcWorkTime(start, end) {
      const deff = (end - start);
      let s = deff / 1000;
      let hours = Math.floor(s / 3600);
      const minutes = Math.floor((s - (hours * 3600)) / 60);

      let str = (hours < 10 ? "0" + hours : hours) + "H:" + (minutes < 10 ? "0" + minutes : minutes) + "M";
      return str;
    },
    ordinalNumbers(number) {
      const numbers = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", "16th", "17th",
        "18th", "19th", "20th", "21st", "22nd"];
      return numbers[number];
    },
    dateTimerFormater(date) {
      let str = date.toLocaleDateString() + " " + date.toLocaleTimeString();
      return str
    },
    dateFormater(date) {
      return date.toLocaleDateString();
    },
    timeFormater(date) {
      let str = date.toLocaleTimeString();
      return date.getHours() + ":" + date.getMinutes() + " " + str.split(' ')[1];
    },
    loadTrackShiftInfo() {
      this.axios.post(ENDPOINTS.TIMESHEET.TIMETRACKING.SHIFTINFO).then((res) => {
        if (res.data.opt === "SUCCESS") {
          this.pauseMode = res.data.pauseMode;
          this.workshift_started = res.data.workshift_started * 1000;
          this.workshift_ended = res.data.workshift_ended * 1000;
          this.currentTime = res.data.currentTime * 1000;
          this.pauseTimeJSON = res.data.pauseTimeJSON;

          this.start = (this.workshift_ended != 0 && this.pauseMode != 1) || (this.workshift_started == 0 && this.workshift_ended == 0);
          this.resume = this.pauseMode == 1;
          this.pause = this.workshift_started != 0 && this.pauseMode != 1;
          this.stop = !this.start;

          //this.workshift_started = this.workshift_started == 0 ? "nicht gestartet" : new Date(this.workshift_started * 1000).toLocaleDateString() + " " + new Date(this.workshift_started * 1000).toLocaleTimeString();
          //this.workshift_ended = this.workshift_ended == 0 ? "noch nicht beendet" : new Date(this.workshift_ended * 1000).toLocaleDateString() + " " + new Date(this.workshift_ended * 1000).toLocaleTimeString();

          if (res.data.workshift_started != 0) {
            this.time = res.data.workshift_started * 1000;
          }


          let currentTime = new Date();
          this.timeTrackTimer = setInterval(
              function () {

                if (this.displayCurrentTime == false) {
                  clearInterval(timeTrackTimer);
                  return;
                }
                currentTime.setSeconds(currentTime.getSeconds() + 1);
                var h = currentTime.getHours();
                var m = currentTime.getMinutes();
                var s = currentTime.getSeconds();


                document.getElementById('hours').innerText = h;
                document.getElementById('minutes').innerText = m < 10 ? "0" + m : m;
                document.getElementById('seconds').innerText = s < 10 ? "0" + s : s;
              }, 1000);
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      });
    },
    continueTracking() {
      this.loadResume = true;
      this.axios.post(ENDPOINTS.TIMESHEET.TIMETRACKING.CONTINUE).then((res) => {
        this.resume = false;
        this.pause = true;
        this.pauseTimeJSON = res.data.pauseTimeJSON;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loadResume = false;
      });
    },
    pauseTracking() {
      this.loadPause = true;
      this.axios.post(ENDPOINTS.TIMESHEET.TIMETRACKING.PAUSE).then((res) => {
        this.resume = true;
        this.pause = false;
        this.pauseTimeJSON = res.data.pauseTimeJSON;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loadPause = false;
      });
    },
    stopTracking() {
      this.loadingStop = true;
      this.axios.post(ENDPOINTS.TIMESHEET.TIMETRACKING.STOP).then((res) => {
        this.resume = false;
        this.pause = false;
        this.stop = false;
        this.start = true;
        this.workshift_ended = res.data.workshift_ended * 1000;// new Date(res.data.workshift_ended * 1000).toLocaleDateString() + " " + new Date(res.data.workshift_ended * 1000).toLocaleTimeString();
        this.time = null;
        this.pauseTimeJSON = res.data.pauseTimeJSON;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loadingStop = false;
      });
    },
    startTracking() {
      this.loadingStart = true;
      this.axios.post(ENDPOINTS.TIMESHEET.TIMETRACKING.START).then((res) => {
        if (res.data.opt === "SUCCESS") {

          this.resume = false;
          this.pause = true;
          this.stop = true;
          this.start = false;
          this.workshift_ended = null;
          this.pauseTimeJSON = null;
          this.workshift_started = res.data.workshift_started * 1000;
          if (res.data.workshift_started != 0) {
            this.time = res.data.workshift_started * 1000;
          }
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
        });
      }).finally(() => {
        this.loadingStart = false;
      });
    }
  }
}
</script>

<style scoped>
#clockdiv div:last-child div {
  background: #ff006a;
}

#clockdiv div div:nth-child(2) {
  height: 40px;
  font-size: 0.7em;
  letter-spacing: 0;
  font-weight: 500;
  z-index: 9;
  box-shadow: none;
  background: #127fd6;
  text-transform: uppercase;
}


#clockdiv {
  font-family: sans-serif;
  color: #fff;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 20px;
  margin: auto;
  padding: 0 0 20px 0;

}

#clockdiv > div {
  padding: 10px;
  border-radius: 3px;
  background: #2196f3;
  display: inline-block;
  position: relative;
  margin: 0 5px;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0004);
}

#clockdiv div span:nth-child(2) {
  height: 30px;
  font-size: 0.7em;
  letter-spacing: 0.2em;
  font-weight: 300;
  z-index: 9;
  box-shadow: none;
  border: none;
  background: #127fd6;
  text-transform: uppercase;
}

#clockdiv div > span {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  background: #2196f3;
  color: #fff;
  font-weight: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  z-index: 10;
}

.pointsContainer {
  background: white !important;
  padding: 0 0 10px 0 !important;
}

.points {
  width: 10px !important;
  height: 60px;
  background: white !important;
  color: #fff;
  font-weight: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  z-index: 10;
}


@media screen and (max-width: 460px) {
  .timerControls {
    width: 40px !important;
    height: 40px !important;
    min-width: 30px !important;
  }

  #clockdiv div > span {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    background: #2196f3;
    color: #fff;
    font-weight: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    z-index: 10;
  }

  #avatar {
    display: none;
  }
}

@media screen and (max-width: 347px) {

  #clockdiv div > span {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    background: #2196f3;
    color: #fff;
    font-weight: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    z-index: 10;
  }
}

#avatar {
  border: 2px solid white !important;
  border-radius: 5px !important;
}

.timerControls {
  width: 60px !important;
  height: 60px !important;
  min-width: 30px !important;
}

#timer {
  font-family: 'Share Tech Mono', monospace;
  color: #ffffff;
  text-align: center;
  color: #676f6f;
  text-shadow: 0 0 10px rgb(129, 157, 167), 0 0 10px rgba(10, 175, 230, 0);
}

.time {
  letter-spacing: 0.05em;
  font-size: 70px;
  padding: 5px 0;
}


.flippy {
  position: relative;
  display: inline-block;
  background-color: #333336;
  border-radius: 20px;
  box-shadow: 0 5px 50x #000;
  color: white;
  height: 120px;
}

.flippy .day {
  text-align: center;
  font-size: 100px;
  line-height: 120px;
  padding: 10px 5px;
  font-weight: bold;
}

.flippy sup {
  font-weight: bold;
  font-size: 12px;
}

.flippy .month {
  position: absolute;
  display: block;
  text-align: center;
  bottom: 10px;
  left: 0;
  width: 100%;
  height: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
}

.flippy:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 60px;
  width: 100%;

  background: -webkit-gradient(linear, 0 0, 0 100%, from(rgba(0, 0, 0, 0)), color-stop(0.4, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.20)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.20) 100%);
  background: -moz-linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.20) 100%);
  background: -o-linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.20) 100%);
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.20) 100%);

  border-radius: 20px 20px 0 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.4);
}

</style>
